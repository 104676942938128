import React, { memo } from 'react';
import {
  Layout,
  LandingTopAnimated,
  LandingWork,
  LandingOverview,
  LandingTechnology,
  LatestBlog,
  LandingPartners,
  LandingTestimonials,
} from '@containers';
import { SEO } from '@components';
import {
  overviewItems,
  backendTechnologies,
  frontendTechnologies,
  mobileTechnologies,
} from '@content';
import './index.scss';

const IndexPage = () => (
  <div>
    <SEO title="Home" />
    <div>
      <div className="on-index-top">
        <LandingTopAnimated
          title="O(n) - we understand complexity"
          text="Ambitious as we may be, we put a lot of work and effort into making fast and secure
        apps. Our experts coordinate, produce and maintain high-level applications at low-level
        code."
        />
      </div>
      <div className="on-index-main">
        <LandingWork text="Have a project you need help with or do you just want to chat with us ?" />
        <LandingOverview text="What we do" items={overviewItems} />
        <LatestBlog />
        <LandingTechnology text="Backend" items={backendTechnologies} />
        <LandingTechnology text="Frontend" items={frontendTechnologies} />
        <LandingTechnology text="Mobile" items={mobileTechnologies} />
     
        {/*<LandingPartners />
        <LandingTestimonials />*/}
      </div>
    </div>
  </div>
);

export default memo(IndexPage);
